.float {
    
    @include media-breakpoint-down(md) {
        @apply left-4 mx-auto;
    }

    &-list {
        @apply flex flex-wrap justify-center gap-4;
        
        @include media-breakpoint-up(md) {
            @apply grid;
        }
    }

    &-link {
        @apply inline-flex items-center justify-center w-14 h-14 rounded-full bg-secondary border-[2px] border-tertier;

        &:hover {
            @apply bg-primary border-white;

            svg {
                @apply fill-white;

                path {
                    @apply fill-white;
                }
            }
        }

        svg {
            @apply w-7 fill-tertier;

            path {
                @apply fill-tertier;
            }
        }

        &__chat {
            background-color: #2AA81A;
            border-color: transparent;

            svg {
                @apply fill-white;

                path {
                    @apply fill-white;
                }
            }
        }
    }
}