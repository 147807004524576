$root-variables: (
    // theme color
    background-color: #354856,
    text-color: #fff,

    // Position Change as you want (Left, Right, Bottom, or Top)
    position-top: auto,
    position-bottom: calc(100% + 8px),

    position-border-top: auto,
    position-border-bottom: 100%,

    position-left: 50%,
    position-right: auto,

    position-border-left: 50%,
    position-border-right: auto,
);

.tooltip {
    @each $key, $value in $root-variables {
        --tooltip-#{$key}: #{$value};
    }
    @apply relative inline-flex;

    &::before, &::after {
        @apply absolute pointer-events-none opacity-0 transition-opacity;
    }
    
    
    &::before {
        content: attr(data-tooltip);
        color: var(--tooltip-text-color);
        background-color: var(--tooltip-background-color);
        @apply absolute w-max py-1 px-2 text-sm;
    }

    &::after {
        @apply block w-0 h-0 border-solid border-4;
        content: '';
        border-color: var(--tooltip-background-color) transparent transparent transparent;
    }

    &:hover {

        &::before, &::after {
            @apply opacity-100;
            transition-delay: 75ms;
        }
    }

    &--position {

        &::before, &::after {
            transform: translate(-50%);
        }

        &::before {
            top: var(--tooltip-position-top);
            bottom: var(--tooltip-position-bottom);
            left: var(--tooltip-position-left);
            right: var(--tooltip-position-right);
        }

        &::after {
            top: var(--tooltip-position-border-top);
            bottom: var(--tooltip-position-border-bottom);
            left: var(--tooltip-position-border-left);
            right: var(--tooltip-position-border-right);
            border-color: var(--tooltip-background-color) transparent transparent transparent;
        }
    }
}