.home {

    &-intro {
        @apply relative;

        @include media-breakpoint-up(lg) {
            @apply py-0;
            height: calc(100vh - (var(--navbar-height)));
        }

        &__body {

            @include media-breakpoint-up(lg) {
                @apply flex items-center;
            }
            
            &-content {
                @apply prose-headings:mb-4 prose-headings:text-primary prose-p:font-semi;

                @include media-breakpoint-up(md) {
                    @apply prose-headings:text-10xl prose-p:text-2xl;
                }

                @include media-breakpoint-up(lg) {
                    @apply w-1/2;
                }
            }

            &-image {
                @apply mt-10 -mx-4;

                @include media-breakpoint-up(lg) {
                    @apply absolute top-1/2 right-0 m-0 w-1/2;
                    transform: translateY(-50%);
                }

                img {
                    @apply w-full;
                }
            }
        }
    }

    &-tab {

        &__body {
            @apply mx-auto;

            @include media-breakpoint-up(lg) {
                max-width: 990px;
            }

            &-desktop {

                @include media-breakpoint-down(lg) {
                    @apply hidden;
                }
            }

            &-mobile {

                @include media-breakpoint-up(lg) {
                    @apply hidden;
                }
            }
        }
    }
    
    &-services {

        &__body {

            &-quote {
                @apply mt-8 mx-auto;

                @include media-breakpoint-up(lg) {
                    @apply mt-14;
                    max-width: 900px;
                }
            }
        }
    }

    &-inquiry {

        &__body {

            &-form {
                @apply mx-auto p-6 bg-grayLight rounded-[32px];

                @include media-breakpoint-up(lg) {
                    @apply py-12 px-16;
                    max-width: 864px;
                }
            }
        }
    }

    &-benefit {

        &__body {
            @apply flex flex-wrap items-center -m-4;

            &-point {
                @apply prose-h2:mb-6 prose-h2:text-primary;
                
                @include media-breakpoint-up(lg) {
                    @apply prose-h2:mb-10 prose-h2:text-8xl;
                }
            }

            &-gallery {
                @apply p-6 bg-grayLight rounded-[32px] prose-h5:mb-6 prose-h5:text-center prose-h5:text-primary overflow-hidden;

                @include media-breakpoint-up(lg) {
                    @apply px-12 py-16 prose-h5:mb-10;
                }

                .item {
                    @apply block aspect-square rounded-sm overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
                }
            }
        }
    }

    &-program {

        &__body {
            @apply mx-auto lg:max-w-[800px];
        }
    }

    &-product {

        &__body {

            &-list {
                @apply mb-14;

                @include media-breakpoint-up(lg) {
                    @apply mb-20;
                    max-width: 960px;
                }
            }
        }
    }
}