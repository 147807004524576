.accordion {

    &-item {
        @apply rounded-rg transition ease-in-out cursor-pointer bg-secondary text-tertier border-tertier;
        border: 2px solid;

        + .accordion-item {
            @apply mt-4;
        }
    }

    &-toggle {

        &[aria-expanded=true] {
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            .icon {

                svg {
                    @apply rotate-180 fill-tertier;
                }
            }
        }

        &[aria-expanded=false] {
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .title {
            @apply flex-1 pr-4;
        }

        .icon {

            svg {
                @apply h-3 fill-tertier;
                transition-duration: .5s;
                transition-property: transform;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }

    &-content {
        transition-duration: .5s;
        transition-property: transform, opacity;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &[aria-hidden=true] {
            height: 0;
            opacity: 0;
            transform: translate3d(0, -0.5rem, 0);
            visibility: hidden;
        }

        &[aria-hidden=false] {
            height: auto;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }
        
        &__detail {
            @apply bg-white mb-4 mx-4 p-4 rounded-rg overflow-hidden;
        }
    }
}