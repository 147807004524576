.media {
    @apply flex items-center p-4;

    @include media-breakpoint-up(lg) {
        @apply p-8;
    }

    &--services {
        box-shadow: 0 8px 24px rgba($color: #000000, $alpha: .1);

        @include media-breakpoint-down(md) {
            @apply flex-col text-center;
        }

        &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(7), &:nth-of-type(8) {
            animation-delay: .25s;
        }
    }

    &--benefit {
        @apply bg-grayLight rounded-[32px];
    }

    &--program {
        content: counter(item) ". ";
        counter-increment: item;
        font-weight: bold;
        margin-right: 5px;

        &:first-child::before {
            counter-reset: item;
        }
    }

    &-image {

        .media--services & {
            @apply mx-auto w-11 h-11;
        }

        .media--benefit & {
            @apply w-20 prose-img:mx-auto prose-img:h-20;
        }
    }

    &-content {
        @apply flex-1;

        .media--services & {
            @apply pt-4;

            @include media-breakpoint-up(md) {
                @apply pt-0 pl-4;
            }
        }

        .media--benefit & {
            @apply pl-6 prose-h5:mb-2 prose-h5:text-tertier prose-p:font-semi;

            @include media-breakpoint-up(lg) {
                @apply prose-h5:text-2xl;
            }
        }
    }
}