@keyframes checkmark {
    0% {
        background-position-y: 5px;
    }
    50% {
        background-position-y: -2px;
    }
    100% {
        background-position-y: 0;
    }
}

.form {
    
    &-check {
        @apply relative inline-flex cursor-pointer;

        input[type='checkbox'] {
            @apply bg-white mt-1;

            &:checked {
                @apply bg-primary border-primary;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                animation: checkmark .2s ease-in;
            }
        }

        input[type='radio'] {
            transition: .25s;

            &:checked {
                @apply bg-primary border-primary;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
                animation: checkmark .2s ease-in;
            }
        }

        label {
            @apply inline-block flex-1 pl-2 mb-0 cursor-pointer;
        }
    }
}