.searchbox {

    &-input {
        @apply bg-transparent p-0 pr-4 h-8 border-0 text-sm text-white placeholder:text-white #{!important};
    }

    &-submit {
        width: 32px;
        height: 32px;
    }
}