.tab {

    &-link {
        @apply inline-flex items-center bg-secondary text-tertier border-tertier;
        border: 2px solid;

        &[aria-selected='true'] {
            @apply bg-transparent text-white border-white;
        }
    }

    &-content {

        &__detail {
            @apply bg-white p-8 overflow-hidden;
            border-radius: 32px;
        }
    }
}