.blockquote {
    @apply relative font-semi italic text-center text-tertier;

    @include media-breakpoint-up(lg) {
        @apply text-2xl;
    }

    &::before, &::after {
        content: '';
        @apply absolute inline-block w-10 h-8 bg-no-repeat bg-contain;
        background-image: url('../images/quote.svg');
    }

    &::before {
        @apply -top-0 left-0 rotate-180;
    }

    &::after {
        @apply bottom-0 right-0;
    }

    p {
        @apply px-10;
    }
}