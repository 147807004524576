@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    html {
        @apply text-rg;
    }

    body {
        @apply leading-normal font-body;
        font-optical-sizing: auto;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-semi leading-tight;
    }

    h1 {
        @apply lg:text-5xl text-4xl;
    }

    h2 {
        @apply lg:text-4xl text-3xl;
    }

    h3 {
        @apply lg:text-3xl text-2xl;
    }

    h4 {
        @apply lg:text-2xl text-1xl;
    }

    h5 {
        @apply lg:text-1xl text-rg;
    }

    h6 {
        @apply text-rg;
    }

    label {
        @apply inline-block mb-1;
    }

    input[type="checkbox"] {
        @apply w-4 h-4 bg-no-repeat border appearance-none outline-none transition ease-in-out cursor-pointer;
    }

    input[type="radio"] {
        @apply w-4 h-4 border rounded-full appearance-none outline-none cursor-pointer;
    }

    a {
        @apply transition ease-in-out;
    }

    .collapse {
        visibility: unset !important;
    }
}