.form {

    &-input, &-textarea, &-select {
        @apply text-black border-grayLight w-full px-4 py-3 transition ease-in-out ring-0 rounded-sm placeholder:text-black focus:border-secondary #{!important};

        &[disabled] {
            @apply bg-grayLight;
        }
    }

    &-validate {

        .form {

            &-input, &-textarea, &-select {
                @apply border-primary placeholder:text-primary #{!important};
            }
        }

        &__feedback {
            @apply mt-1 text-primary;

            small {
                @apply text-sm;
            }
        }
    }

    &-readonly {
        @apply bg-white border border-grayLight w-full px-4 py-3 transition ease-in-out ring-0 rounded-sm;

        span {
            @apply text-primary;
        }
    }

    &-info {
        @apply text-sm;
        
        span {
            @apply text-primary;
        }
    }

    &-submit {

        .wrap-captcha {
            @apply flex justify-center;

            @include media-breakpoint-down(lg) {
                transform: scale(.8);
            }
        }
    }
}