$root-variables: (
    // base color
    base-color: #000,
    white-color: #fff,
    // navbar
    navbar-height: 88px,

    // loading regular
    loading-size: 1.25rem,

    // loading small
    loading-sm-size: 1rem,

    // loading large
    loading-lg-size: 1.5rem,

    // loading extra large
    loading-xl-size: 2rem,
);

:root {

    @each $key, $value in $root-variables {
      --#{$key}: #{$value};
    }
}

[data-aos] {
  visibility: hidden;
}
[data-aos].animate__animated {
  visibility: visible;
  pointer-events: inherit;
}
  