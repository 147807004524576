.stray {

    @include media-breakpoint-up(lg) {
        height: calc(100vh - var(--navbar-height));
    }

    &-dialog {
        @apply grid gap-8;

        @include media-breakpoint-up(lg) {
            @apply grid-cols-2 mx-16;
        }
    }

    &-body {
        @apply prose-headings:mb-6 prose-headings:text-primary;

        @include media-breakpoint-up(lg) {
            @apply prose-headings:text-8xl;
        }

        .actions {
            @apply grid gap-4 mt-8;

            @include media-breakpoint-up(lg) {
                @apply flex gap-8;
            }
        }
    }
}