.navbar {
    @apply bg-primary text-white;
    transition: ease-in-out .25s, height .25s;

    @include media-breakpoint-down(md) {
        @apply py-6;
    }

    @include media-breakpoint-up(md) {
        height: var(--navbar-height);
    }

    &-logo {
        @apply mr-auto;

        img {
            @apply h-7;
        }
    }

    &-toggle {
        @apply w-10 h-10 border border-white rounded-sm;
    }

    &-menu {

        @include media-breakpoint-down(md) {
            @apply w-full mt-6;
        }

        @include media-breakpoint-up(md) {
            @apply flex #{!important};
        }
    }
}