.pagination {

    &-item {

        &__button {
            @apply bg-grayLight w-10 h-10 font-semi;

            &.current {
                @apply bg-grayLight text-white;
            }
        }
    }
}