.footer {

    &-main {

        &__title {

            &-list {

                &-item {
                    @apply inline-flex prose-img:h-4 prose-img:mx-auto;

                    .icon {
                        @apply w-4 mr-3;
                        margin-top: 2px;
                    }
                }
            }
        }

        &__menu {

            @include media-breakpoint-up(lg) {
                margin-top: 36px;
            }
        }
    }

    &-social {

        &__body {
            @apply lg:flex lg:justify-between;
        }

        &__image {

            @include media-breakpoint-down(lg) {
                @apply justify-center mb-8;
            }
        }

        &__list {

            @include media-breakpoint-down(lg) {
                @apply justify-center;
            }

            &-item {
                @apply w-10 h-10 bg-grayLight rounded-full overflow-hidden prose-img:h-5;
            }
        }
    }
}