.section {
    @apply py-14 lg:py-20;

    &-title {
        @apply mb-8 prose-headings:text-2xl;

        @include media-breakpoint-up(lg) {
            @apply mb-14 prose-headings:text-8xl;
        }

        .home-tab & {
            @apply flex items-center justify-center gap-8;

            @include media-breakpoint-down(md) {
                @apply gap-2 text-center prose-headings:flex-1;

                img {
                    @apply h-14;
                }
            }
        }

        .home-call & {
            @apply mx-auto;

            @include media-breakpoint-up(lg) {
                max-width: 1040px;
            }
        }

        .home-product & {
            @apply prose-headings:mb-5;

            .captions {
                @apply mx-auto;

                @include media-breakpoint-up(lg) {
                    max-width: 864px;
                }
            }
        }
    }
}