.card {
    @apply transition ease-in-out;

    &--program {
        content: counter(item) ". ";
        counter-increment: item;
        font-weight: bold;
        box-shadow: 0 4px 4px rgba($color: #000000, $alpha: .25);
        @apply p-4 text-center;

        &:first-child::before {
            counter-reset: item;
        }

        @include media-breakpoint-up(lg) {
            @apply px-16 py-8;
        }

        &:nth-of-type(2) {
            animation-delay: .25s;
        }

        &:nth-of-type(3) {
            animation-delay: .5s;
        }

        &:nth-of-type(4) {
            animation-delay: .75s;
        }
    }

    &--product {

        &:hover {

            .card {

                &-header {

                    img {
                        height: calc(280px - (64px - 16px));
                        
                        @include media-breakpoint-up(lg) {
                            height: calc(280px + 16px);
                        }
                    }
                }
            }
        }
    }

    &-header {

        .card--program & {
            @apply prose-h2:mb-4 prose-h2:font-selection prose-h2:text-secondary prose-img:mx-auto;

            @include media-breakpoint-up(lg) {
                @apply prose-h2:mb-8 prose-h2:text-8xl;
            }

            h2 {
                text-shadow: -3px -3px 0 #510F15, 0 -3px 0 #510F15, 3px -3px 0 #510F15, 3px 0 0 #510F15, 3px 3px 0 #510F15, 0 3px 0 #510F15, -3px 3px 0 #510F15, -3px 0 0 #510F15;
            }
        }

        .card--product & {
            @apply flex items-end justify-center mx-auto;
            width: calc(280px - 64px);
            height: calc(280px - 64px);

            img {
                @apply mb-3;
                height: calc(280px - 64px);
                transition: .3s ease-in-out;
            }
            
            @include media-breakpoint-up(lg) {
                width: 280px;
                height: 280px;

                img {
                    @apply mb-6;
                    height: 280px;
                }
            }
        }
    }

    &-content {

        .card--program & {
            @apply mt-4;

            @include media-breakpoint-up(lg) {
                @apply mt-8;
            }

            h5 {

                &::before {
                    content: counter(item) ". ";
                    @apply relative;
                }
            }
        }
    }
}