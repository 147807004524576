@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.dropdown {

    &-menu {
        animation: fadeIn .25s ease-in-out;

        &-end {
            @apply right-0;
        }

        .dropdown--lang & {
            @apply py-3 px-4 shadow-md;

            &__list {

                img {
                    @apply h-4;
                }
            }
        }
    }
}